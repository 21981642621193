/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {createProductAppl, fetchOwnCompany, fetchPackagingUnits} from "./actions/actions";
import ProductApplEditComponent from "./ProductApplEditComponent";
import {ActionTypes, GLN, GTIN, SSCC, TOKEN} from "./Constants";
import {toast} from "react-toastify";
import {
    capitalizeFirstLetter,
    checkGlnFieldsFilled,
    checkGtinFieldsFilled,
    checkSsccFieldsFilled
} from "./utils/productUtils"
import {useNavigate, useParams} from "react-router-dom";


const TITLE_DEFAULT = "Бүтээгдэхүүний анкет";

const initialState = {
    product: {barcode_type:GTIN},
    loading: false,
    title:TITLE_DEFAULT,
    units:[]
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units:action.units,
                loading: false
            }
        }
        case 'CHANGE_PACKAGING': {
            let c = {...state.product};
            c.packaging_unit = action.unit;

            return {
                ...state,
                product: c,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.product};
            c[action.name] = action.value
            return {
                ...state,
                product: c
            }
        case 'SET_TITLE':
            return {
                ...state,
                title: action.title
            }
        default:
            return state;
    }
};



const changeField = (name, v) => {
    let value = capitalizeFirstLetter(v)
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const setTitle = (title) => {
    return {
        type: 'SET_TITLE',
        title
    }
}


const changePackaging = (unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        unit
    }
}

const ProductApplCreate = ( ) => {
    let {tp} = useParams();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {product, loading, title, units} = state;
    const navigate = useNavigate();
    useEffect(() => {
        fetchOwnCompany( dispatch).then (response => {
                if (response.data.invoices.length > 0) {
                    toast.success("Нэхэмжлэх ирсэн байна!");
                    navigate("/invoices")
                }
            }
        ).catch(error=> {
            alert(error)
        });
    }, []);

    useEffect(() => {
        console.log('useEffect fetchPackagingUnits');
        fetchPackagingUnits(dispatch)
    }, []);

    if ( tp === undefined) {
        product.barcode_type = GTIN;

        console.log("undefined");
    } else if (tp === SSCC) {
        product.barcode_type = SSCC;

    } else if (tp === GLN) {
        product.barcode_type = GLN;

    } else {
        product.barcode_type = GTIN;

    }

    useEffect(() => {
        if ( product.barcode_type === undefined) {
            dispatch(setTitle(TITLE_DEFAULT));
        } else if (product.barcode_type === SSCC) {
            dispatch(setTitle(TITLE_DEFAULT+"-"+SSCC));
        } else if (product.barcode_type === GLN) {
            dispatch(setTitle(TITLE_DEFAULT+"-"+GLN));
        } else {
            dispatch(setTitle(TITLE_DEFAULT));
        }

    }, [tp]);

    const handleChangePackagingUnit = (e) => {
        dispatch(changePackaging(e.target.value))
    };

    const handleSave = () => {
        let checkFailed = false;
        if (
            product.barcode_type === GTIN  ) {
            if (!checkGtinFieldsFilled(product)) {
                console.log('save check gtin');
                checkFailed = true;
            }
        } else if (product.barcode_type === GLN) {
            if (!checkGlnFieldsFilled(product)) {
                checkFailed = true;
            }
        } else if (product.barcode_type === SSCC) {
            if (!checkSsccFieldsFilled(product)) {
                checkFailed = true;
            }
        }
        if (checkFailed) {
            console.log('save check gtin');
            alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
            return;
        }

        let dto = {}
        if (product.barcode_type === GTIN) {
            dto = product
        } else if (product.barcode_type === GLN) {
            dto = product;
        } else if (product.barcode_type === SSCC) {
            dto = product
        }
        createProductAppl(dispatch, token, dto).then(response => {
                toast.success("Амжилттай илгээлээ!");
                navigate("/requests")
            }
        ).catch(error => {
            alert(error)
        })

    };

    const handleCancel = () => {
        console.log('cancel');
        navigate(-1);
        //history.back();
    };
    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }

    return (
        <div className="m-4">

            <h2 className="text-2xl font-bold m-2">{title}</h2>
            <hr/>
            <ul className="list-disc ml-6 my-5">
                <li>
                    Монголоор бичнэ. / Галигаар бичихгүй /
                </li>
                <li>
                    Одтой хэсгийг заавал бөглөнө.
                </li>
                <li>
                    Бүх хүсэлтээ илгээж дуусаад заавал гарах эсвэл refresh товчоо заавал дарсан байх.
                </li>
                <li>
                    Зураасан код орсны дараа эргэж нэвтэрч ороод GCP хэсгээс файлаар татаж авна.
                </li>
            </ul>

            <ProductApplEditComponent
                product={product}
                onChangeField={handleChangeField}
                onSave={handleSave} onCancel={handleCancel}
                packaging_units={units} onChangePackagingUnit={handleChangePackagingUnit}
            />

        </div>

    )

}


export default ProductApplCreate