import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {Endpoints} from './Constants';
import axios from './axiosConfig'
import {format, parseISO} from 'date-fns'

const CompanyProducts = () => {
    const [data, setData] = useState({products:[],meta : {totalPages:0, totalElements:0, page:0,size:25}});
    const [name, setName] = useState(null);
    const [barcode, setBarcode] = useState(null);
    const [page, setPage] = useState(0);
    const [toggle, setToggle] = useState(false);
    const [pagesize, setPageSize] = useState(25);
    const [orderField, setOrderField] = useState("id");
    const [isAsc, setIsAsc] = useState(true);



     useEffect(() => {
         async function getProducts() {
             let url = `${Endpoints.PRODUCTS}/own/?` + qs.stringify({name:name, barcode:barcode, page:page, pagesize:pagesize, order:orderField, asc:isAsc}, {skipNull:true});
             const response = await axios.get(url);
             setData(response.data);
         }
         getProducts();
             //dispatch(fetchProducts(name, barcode, page, pagesize, orderField, isAsc));
     }, [ toggle]);

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeBarcode = (e) => {
        setBarcode(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setPage(0);
        setOrderField("id");
        setIsAsc(true);
        setToggle(!toggle);
    };

    const handlePageClick = (data) => {

        let selected = data.selected;
        setPage(data.selected);
        setToggle(!toggle);
    };

    const handleOrderClick = (f) => {

        if ( f === orderField) {
            setIsAsc(!isAsc)
        } else {
            setOrderField(f)
            setIsAsc(true)
        }
        setToggle(!toggle);
    }

    let counter = Number(data.meta.page) * Number(data.meta.size);
    const items = data.products.map(
        (p) => {
            counter++;
            return (
                <tr key={p.id}>
                    <td className="border px-8 py-4">{counter}</td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline hover:cursor-pointer" to={`/product/${p.id}`}>
                            {p.name}
                        </Link>
                    </td>
                    <td className="border px-8 py-4">
                            {p.brand_name}
                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline hover:cursor-pointer" to={`/product/${p.id}`}>
                            {p.barcode}
                        </Link>
                    </td>
                    <td className="border px-8 py-4">{p.packaging} {p.packaging_unit}</td>
                    <td className="border px-8 py-4">{p.created && format(parseISO(p.created), "yyyy-MM-dd")}</td>
                </tr>
            )
        });

    return (
        <div className="m-4">

            {/*<h2 className="font-bold text-2xl mb-2">Бүтээгдэхүүнүүд</h2>*/}
            <ProductsSearchPanel
                name={name} barcode={barcode}
                onChangeName={handleChangeName}
                onChangeBarcode={handleChangeBarcode}
                onClickSearch={handleSearch}
            />

            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4" onClick={() => handleOrderClick("name")}>
                        <div  className="text-blue-500 underline hover:cursor-pointer">Нэр</div>
                    </th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бренд Нэр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">
                        <a className="text-blue-500 underline hover:cursor-pointer" onClick={(e)=>handleOrderClick("barcode")}>Баркод/GTIN</a>
                    </th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Савлалтын хэмжээ /нэгж, багц/</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Үүсгэсэн</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="my-2">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<span>...</span>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={data.meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeClassName="bg-blue-300 hover:bg-blue-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                               pageClassName="text-gray-500 px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                               pageLinkClassName=""
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={""}
                               previousLinkClassName="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               nextClassName={""}
                               nextLinkClassName="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               containerClassName="inline-flex items-center -space-x-px"
                               forcePage={page}
                />
            </nav>
            <br/><br/>
        </div>
    )

}



const ProductsSearchPanel = ({
                                 name, barcode,
                                 onChangeName,
                                 onChangeBarcode,
                                 onClickSearch
                             }) => {

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Нэр</label>
                        <input name="name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={name} style={{width: '400px'}}
                               onChange={onChangeName} placeholder="Оруулах"/>
            </div>

            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Barcode</label>
                        <input name="barcode" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={barcode} style={{width: '400px'}}
                               onChange={onChangeBarcode} placeholder="Оруулах"/>
            </div>

            <div className="flex items-center justify-between my-2">
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" onClick={onClickSearch}>
                    Хайх
                </button>
            </div>
            {/*<div className="field is-horizontal">*/}
            {/*    <div className="field-label">*/}

            {/*    </div>*/}
            {/*    <div className="field-body">*/}
            {/*        <div className="field">*/}
            {/*            <div className="control buttons">*/}

            {/*                <button onClick={onClickSearch} className="button bg-orange">*/}
            {/*                    <span style={{color: "white"}}>Хайх</span>*/}

            {/*                </button>*/}

            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    )
}

//
// const mapStateToProps = (state, ownProps) => {
//     let isUpdating = state.ui.isUpdating;
//
//     const companyId = ownProps.match.params.id
//     let products = state.companies.products;
//     let meta = state.companies.meta;
//     let pagesize = 25;
//     let counter = 0
//     let order = "barcode"
//     let asc = true
//     for (let key in products) {
//         let item = products[key]
//         counter ++
//         item.counter = meta.page * meta.size + counter
//     }
//
//     return {
//         meta,
//         pagesize,
//         order,
//         asc,
//         isUpdating,
//         products,
//         companyId
//     }
// }
//


//export default connect(mapStateToProps)(CompanyProducts)

export default CompanyProducts


